<template>
    <div class="contentWrapper">
      <div class="sideListContainer">
        <div class="sideList" @addUser="addUser">
          <DataTable
            :value="users"
            :scrollable="true"
            scrollHeight="flex"
            :loading="loading"
            dataKey:="id"

            filterDisplay="menu"
            v-model:filters="filter"
            :globalFilterFields="['name', 'email']"
          >
            <template #header>
                <h6 class="mt-2 ml-2"><span class="p-menuitem-icon fas fa-users" aria-hidden="true"></span> Swiftdox Users</h6>
              <div class="flex">
                <div class='flex-grow-1'>
                <span class="p-input-icon-left w-full">
                  <i class="fa fa-search" />
                  <InputText
                    v-model="filter['global'].value"
                    placeholder="Search"
                    :class="'fullWidthInput'"
                    type="search"
                  />
                </span>
                </div>
                <div class='flex align-items-center pl-3'>
                  <router-link :to="{name: 'NewUser'}">
                  <Button icon="fas fa-plus" class="p-button-rounded-light"/>
                  </router-link>
                </div>
              </div>

            </template>

            <Column field="name" header="Name" :sortable="true"></Column>
            <Column field="email" header="Email" :sortable="true"></Column>
            <Column header="Edit" :class="'icon-col'">
              <template #body="{data}">
                <router-link
                  :to="{ name: 'UserDetail', params: { id: data.id } }"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <div class="mainContent">
        <router-view @addUser="addUser"></router-view>
      </div>
    </div>
</template>

<script>

import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'Users',
  components: {},
  data() {
    return {
      loading: false,
      users: [],
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  }, // data()
  mounted() {
    this.loading = true;
    this.axios.get('/users').then((response) => {
      this.users = response.data;
      this.loading = false;
    });
  }, // mounted()
  methods: {
    addUser(userInfo){
      console.log("Main-AddUser", userInfo)
      this.users.push(userInfo)
      this.$router.push({ path: "/users/"+userInfo.id })      
    }
  }, // methods
};
</script>
