<template>
  <div class="card">
    <h5 class="text-center">Create User</h5>

    <form @submit.prevent="handleSubmit" class="p-fluid" autocomplete="off">
      <div class="field">
        <span class="p-float-label p-input-icon-right">
          <i class="fas fa-user" />
          <InputText
            id="name"
            v-model="name"
            autocomplete="off"
            :class="{ 'p-invalid': v$.name.$error }"
          />
          <label for="name" :class="{ 'p-error': v$.name.$error }">Name*</label>
        </span>

        <small v-if="v$.name.$error" class="p-error help-text">{{
          v$.name.$errors[0].$message
        }}</small>
        <pre>{{v$.name.$errors}}</pre>

      </div>

      <div class="field">
        <div class="p-float-label p-input-icon-right">
          <i class="fas fa-envelope" />
          <InputText
            id="email"
            v-model="email"
            autocomplete="off"
            :class="{ 'p-invalid': v$.email.$error }"
            aria-describedby="email-error"
          />
          <label for="email" :class="{ 'p-error': v$.email.$error }"
            >Email*</label
          >
        </div>
        <span v-if="v$.email.$error" id="email-error" class="p-error help-text">
          {{ v$.email.$errors[0].$message}}
        </span>
        <pre>{{v$.email.$errors}}</pre>
      </div>

      <div class="field">
        <div class="p-float-label">
          <Password
            id="password"
            v-model="password"
            :showIcon="'fas fa-eye'"
            :hideIcon="'fas fa-eye-slash'"
            :class="{ 'p-invalid': v$.password.$error }"
            toggleMask
            :feedback="false"
          />

          <label for="password" :class="{ 'p-error': v$.password.$error }"
            >Password*</label
          >
        </div>
        <small v-if="v$.password.$error" class="p-error help-text">{{
          v$.password.$errors[0].$message
        }}</small>
        <pre>{{v$.password.$errors}}</pre>
      </div>

      <Button type="submit" label="Submit" class="p-mt-2" />
    </form>
  </div>
</template>

<script>
import {
  helpers,
  email,
  required,
  minLength,
  maxLength,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import Password from 'primevue/password';

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Password,
  },

  data() {
    return {
      name: '',
      email: '',
      password: '',
    };
  },
  validations() {
    return {
      name: {
        required:  helpers.withMessage('A user name is required', required),
        minLength: helpers.withMessage('Min 4 characters', minLength(4)),
        maxLength: helpers.withMessage('Max 32 characters', maxLength(32)),
      },
      email: {
        required: helpers.withMessage('An email address is required', required),
        email: helpers.withMessage('Enter a valid email address', email),
      },
      password: {
        required: helpers.withMessage('A password is required', required),
        minLength: helpers.withMessage('Min 8 characters', minLength(6)),
        maxLength: helpers.withMessage('Max 32 characters', maxLength(32)),
      },
    };
  }, // validations()

  emits: ["addUser"],
  methods: {
    resetForm() {
      this.name = '';
      this.email = '';
      this.password = '';
    }, // resetForm()
    handleSubmit() {
      this.v$.$touch();
      if (this.v$.$error) return false;
      let userInfo = {name: this.name, email: this.email, password: this.password};
      console.log(userInfo)

      this.axios.post('/user/create', userInfo).then(response=>{
        userInfo.id=response.data;
        this.$emit('addUser', userInfo)
      })
    },
  }, // methods()
};
</script>

<style scoped>
.field {
  margin-top: 2rem;
}
</style>
