<template>
  <div class="card">
    <TabView lazy v-model:activeIndex="activeTab" >
      <TabPanel>
        <template #header>

          <span><i class="fas fa-info-circle"></i>  User Info</span>
        </template>
        <user-info :user="user" :display="display"/>
      </TabPanel>

      <TabPanel>
        <template #header>

          <span><i class="fas fa-key"></i>  Change Password</span>
        </template>
        <user-password :user="user" />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import UserInfo from '@/views/System/Users/Info.vue';
import UserPassword from '@/views/System/Users/Password.vue';

export default {
  name: 'UserOptions',
  components: {
    TabView, TabPanel, UserInfo, UserPassword,
  },
  props: ['id'],
  data() {
    return {
      user: {},
      activeTab: 0,
      display: 'static',

    };
  }, // data()

  mounted() {
    this.axios
      .get(`/user/${this.id}`)
      .then((response) => (this.user = response.data));
  },
  watch: {
    id() {
      this.axios
        .get(`/user/${this.id}`)
        .then((response) => {
          this.user = response.data;
          this.activeTab = 0;
          this.display = 'static';
        });
    },
  }, // watch()
};
</script>
