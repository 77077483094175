<template>
  <div class="grid" v-if="display==='static'">
    <div class="col-12">
      <p>User Name: {{ user.name }}</p>
    </div>
    <div class="col-12">
      <p>Email: {{ user.email }}</p>
    </div>
  </div>

  <div class="grid" v-if="display==='form'">
    <div class="col-12">
      <p>Form {{ user.name }}</p>
    </div>

  </div>

</template>

<script>
export default {
  name: 'UserInfo',
  props: ['user', 'display'],
};
</script>
