<template>
  <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
    <div class="field mt-5">
      <div class="p-float-label">
        <Password
          id="password"
          v-model="password"
          :class="{ 'p-invalid': v$.password.$invalid && submitted }"
          :feedback="false"
          toggleMask
        >
        </Password>
        <label
          for="password"
          :class="{ 'p-error': v$.password.$invalid && submitted }"
          >Password*</label
        >
      </div>
      <small v-if="v$.password.$error" class="p-error">{{
        v$.password.$errors[0].$message
      }}</small>
    </div>

    <Button type="submit" label="Submit" class="p-mt-2" />
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, minLength } from '@vuelidate/validators';


import Password from 'primevue/password';

export default {
  name: 'ChangeUserPassword',
  props: ['user'],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Password,
  },

  data() {
    return {
      password: '',
      submitted: false,
      showMessage: false,
    };
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage(
          () => 'Enter a new password.',
          required,
        ),
        minLength: helpers.withMessage(
          () => 'Password must have a minimum of 8 characters.',
          minLength(8),
        ),
      },
    };
  },
  created() {
    //console.log('User', this.$store.state.user);
  }, // created()
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;
      this.v$.$touch();

      if (!isFormValid) {
        return;
      }
      this.axios
        .post('/changePassword', { password: this.password, email: this.user.email })
        .then(() => {
          this.password = '';
          this.v$.$reset();
          this.$swal({
            icon: 'success',
            text: 'Password has been reset!',
          });
        });
    },
  },
};
</script>
